import WaiterIcon from '@/assets/svg/Waiter';
import { useMain } from '@/contexts/main';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { CallWaiter } from '@/services/home';
import { Button, CircularProgress, Modal } from '@mui/material';
import React from 'react';

type CompType = {
  open: boolean;
  handleClose(): void;
};

export default function ModalWaiter({ open, handleClose }: CompType) {
  const dispatch = useAppDispatch();
  const { responseLogin } = useAppSelector((state) => state.auth);
  const { loadingWaiter } = useAppSelector((state) => state.home);
  const { storageLogin } = useMain();
  const personalData = responseLogin || storageLogin;

  async function onCall() {
    if (personalData) {
      await dispatch(
        CallWaiter({
          nr_mesa: personalData?.nr_mesa,
        })
      );
    }
  }

  return (
    <Modal
      open={open}
      id="modalWaiter"
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      onClose={handleClose}
    >
      <div className="content">
        {loadingWaiter ? (
          <>
            <CircularProgress className="loading" />
            <p className="title title-noMargin">Chamando garçom...</p>
          </>
        ) : (
          <>
            <div className="icon">
              <WaiterIcon />
            </div>
            <p className="title">Deseja chamar o garçom?</p>
            <div className="rowBtn">
              <Button variant="contained" className="btn" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="contained" className="btn" onClick={onCall}>
                Chamar
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
