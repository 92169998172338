'use client';

import Head from 'next/head';
import React, { Fragment } from 'react';

type HeadProps = {
	title: string;
	img: string;
	facebook: string;
	globalSiteTag: string;
	globalSiteId: string;
};

export default function HeadComp({ title, img, facebook, globalSiteId, globalSiteTag }: HeadProps) {
	return (
		<Head>
			<meta name="viewport" content="width=device-width, initial-scale=1" />
			<title>{title || 'Zero Fila'}</title>
			<style dangerouslySetInnerHTML={{ __html: `</style>${facebook}<style>` }}></style>
			<style dangerouslySetInnerHTML={{ __html: `</style>${globalSiteTag}<style>` }}></style>
			<style dangerouslySetInnerHTML={{ __html: `</style>${globalSiteId}<style>` }}></style>
			<meta property="og:title" content={title || 'Zero Fila'} key="title" />
			<meta property="og:description" content="Zerofila - Diga sim à simplicidade" key="description" />
			<meta property="og:image" content={img} key="image" />
		</Head>
	);
}
