import { useAppDispatch } from '@/redux/hooks';
import { setOrder } from '@/services/home';
import { Button, Modal, Select, FormControl, MenuItem, InputLabel } from '@mui/material';
import React, { useState } from 'react';

type Props = {
	open: boolean;
	setOpen: Function;
};

export default function ModalFilter({ open, setOpen }: Props) {
	const dispatch = useAppDispatch();
	const [filterOption, setFilterOption] = useState('DEF');

	const handler = () => {
		dispatch(setOrder(filterOption));
		setOpen(false);
	};

	return (
		<Modal open={open} id="modalPix" disablePortal disableEnforceFocus disableAutoFocus disableScrollLock style={{ zIndex: 999 }}>
			<div className="pixContent">
				<div>
					<br />
					<p className="pixTitle">Em qual formato deseja reordenar o cardápio?</p>
					<br />
					<FormControl fullWidth>
						<InputLabel id="reorder-label">Classificação do Cardápio</InputLabel>
						<Select
							labelId="reorder=label"
							id="reorder"
							value={filterOption}
							label="Classificação do Cardápio"
							onChange={(ev) => {
								setFilterOption(ev.target.value);
							}}>
							<MenuItem value={'DEF'}>Padrão</MenuItem>
							<MenuItem value={'NASC'}>Nome - Ordem Crescente</MenuItem>
							<MenuItem value={'NDESC'}>Nome - Ordem Decrescente</MenuItem>
							<MenuItem value={'PASC'}>Preço - Ordem Crescente</MenuItem>
							<MenuItem value={'PDESC'}>Preço - Ordem Decrescente</MenuItem>
						</Select>
					</FormControl>
					<br /> <br />
					<Button variant="contained" className="btnPix" onClick={handler}>
						Ok
					</Button>
				</div>
			</div>
		</Modal>
	);
}
